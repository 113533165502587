import { formatNumber } from '@angular/common';
import { FormatType } from '../enums/format-type.enum';
import { TextConstants } from '../text-constants';
import { CurrencyCode, CurrencyHelper } from './currency.helper';
import { DateHelper, DateTimeFormat } from './date.helper';
import { TextHelper } from './text.helper';

export class FormatHelper {
  private static _localeId: string;

  static getLocale(): string {
    return FormatHelper._localeId;
  }

  static initializeLocale(localeId: string): () => string {
    return () => (FormatHelper._localeId = localeId);
  }

  // FORMAT CLASS
  static format(value: number | string | Date | null, type: FormatType, addSign = false, tooltip = false, unit?: string): string {
    if ((value instanceof Number && Number.isNaN((value as number))) || value == null) {
      return TextConstants.notAvailable;
    }

    let result: string;
    if (value instanceof String) {
      result = value as string;
    } else if (addSign && type !== FormatType.Percentage) {
      result = this.addSign(this.format(value, type, false, tooltip, unit));
    } else {
      result = this.formatByType(value, type, addSign, tooltip);
    }

    result = unit ? `${result} ${unit}` : result;
    result = TextHelper.convertSpacesToNbsp(result);
    return result;
  }

  private static formatByType(value: number | string | Date | null, type: FormatType, addSign: boolean, tooltip: boolean): string {
    switch (type) {
      case FormatType.Number:
        return tooltip ? this.formatNumberWithDecimals(Number(value)) : this.formatNumber(Number(value));
      case FormatType.NumberHours:
        return tooltip
          ? Number(value) === 1
            ? this.formatNumberWithDecimals(Number(value)) + '\u00A0hour'
            : this.formatNumberWithDecimals(Number(value)) + '\u00A0hours'
          : this.formatNumber(Number(value)) + '\u00A0h';
      case FormatType.Percentage:
        return this.formatPercentage((value as number), addSign, tooltip);
      case FormatType.Currency:
        return this.formatCurrency((value as number), addSign, tooltip);
      case FormatType.Currency4:
        return this.formatCurrency4((value as number), addSign, tooltip);
      case FormatType.Time:
        return this.formatTime((value as number), tooltip);
      case FormatType.TimeAgo:
        return this.formatTime((value as number), tooltip, false) + '\u00A0ago';
      case FormatType.DaysAge:
        return DateHelper.getDaysAge((value as Date));
      case FormatType.Text:
        return value as string;
      case FormatType.Date:
        return DateHelper.formatDate((value as Date), DateTimeFormat.ShortMonthNameDayYear);
      case FormatType.DateWithoutYear:
        return DateHelper.formatDate((value as Date), DateTimeFormat.ShortMonthNameDay);
      case FormatType.DateTime:
        return DateHelper.formatDate((value as Date), DateHelper.fullDateTimeFormat);
      case FormatType.MonthAndYear:
        return DateHelper.formatDate((value as Date), DateTimeFormat.MonthNameYear);
      case FormatType.HourMins:
        return DateHelper.formatDate((value as Date), DateHelper.hourTimeFormat);
      case FormatType.HourBucket:
        const hour = Math.trunc((value as number));
        return `${DateHelper.formatTime(hour * 60 * 60 * 1000, DateHelper.is24HFormat ? DateTimeFormat.Time24hHour : DateTimeFormat.Time12hHour)} - ${DateHelper.formatTime(
          (hour + 1) * 60 * 60 * 1000,
          DateHelper.is24HFormat ? DateTimeFormat.Time24hHour : DateTimeFormat.Time12hHour,
        )}`;
      case FormatType.Bytes:
        return this.formatBytes((value as number), 2);
      case FormatType.Fte:
        return this.formatNumberWithDecimals((value as number)) + '\u00A0FTE';
      default:
        return value.toString();
    }
  }

  // NUMBER FORMATTING
  private static formatNumber(value: number): string {
    if (isNaN(value)) {
      return '-';
    } else if (value === 0) {
      return '0';
    } else if (value < 0) {
      return this.getOpposite(this.formatNumber(-value), '0');
    }

    let displayValue = value;
    let postfix = '';
    if (value >= 1000000) {
      displayValue = value / 1000000;
      postfix = 'mil';
    } else if (value >= 10000) {
      displayValue = value / 1000;
      postfix = 'k';
    }
    const pow = displayValue >= 100 ? 1 : displayValue < 10 && postfix !== '' ? 100 : 10;
    return `${formatNumber(Math.round(displayValue * pow) / pow, this._localeId)}${postfix}`;
  }

  private static getOpposite(formattedValue: string, zero: string): string {
    return formattedValue !== zero ? `- ${formattedValue}` : zero;
  }

  private static formatNumberWithDecimals(value: number): string {
    if (!value || value === 0) {
      return '0';
    }
    if (value < 0) {
      return this.getOpposite(this.formatNumberWithDecimals(-value), '0');
    }

    // if number is greater or equal then 1 use 2 decimal places else use 2 valid places
    let pow = 100;
    if (value < 1) {
      const log = Math.floor(Math.log10(value));
      pow = Math.pow(10, 1 - log);
    }
    return formatNumber(Math.round(value * pow) / pow, this._localeId, '1.0-10');
  }

  private static formatNumberForPrices(value: number, precision = 4): string {
    if (!value || value === 0) {
      return '0';
    }
    if (value < 0) {
      return this.getOpposite(this.formatNumberForPrices(-value), '0');
    }

    // if number is greater or equal then 1 use 4 decimal places else use 4 valid places
    let pow = Math.pow(10, precision);
    if (value < 1) {
      const log = Math.floor(Math.log10(value));
      pow = Math.pow(10, 1 - log);
    }
    return formatNumber(Math.round(value * pow) / pow, this._localeId, '1.0-10');
  }
  // end of NUMBER FORMATTING

  // PERCENTAGE FORMATTING
  private static formatPercentage(value: number, addSign = false, tooltip = false): string {
    if (isNaN(value)) {
      return TextConstants.notAvailable;
    } else if (value === 0) {
      return '0%';
    }
    return `${this.format(value * 100, FormatType.Number, addSign, tooltip)}%`;
  }

  // CURRENCY FORMATTING
  // currency shoud be taken from Process | Organization
  // TODO: would be better to have it like locale and use Intl.NumberFormat
  private static formatCurrency(value: number, addSign = false, tooltip = false): string {
    const strValueWithSignToCurrency = (strVal: string, symbol: string) => {
      const firstChar = strVal?.substring(0, 1);
      if (['+', '-'].includes(firstChar)) {
        return `${firstChar} ${symbol}${strVal.substring(1, strVal.length - 1).trim()}`;
      } else {
        return `${symbol}${strVal}`;
      }
    };

    switch (CurrencyHelper.currencySymbol) {
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.CZK):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.ISK):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.PLN):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.SEK):
        return `${this.format(value, FormatType.Number, addSign, tooltip)} ${CurrencyHelper.currencySymbol}`;
      default:
        return strValueWithSignToCurrency(this.format(value, FormatType.Number, addSign, tooltip), CurrencyHelper.currencySymbol);
    }
  }

  private static formatCurrency4(value: number, addSign = false, tooltip = false): string {
    const strValueWithSignToCurrency = (strVal: string, symbol: string) => {
      const firstChar = strVal?.substring(0, 1);
      if (['+', '-'].includes(firstChar)) {
        return `${firstChar} ${symbol}${strVal.substring(1, strVal.length - 1).trim()}`;
      } else {
        return `${symbol}${strVal}`;
      }
    };

    switch (CurrencyHelper.currencySymbol) {
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.CZK):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.ISK):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.PLN):
      case CurrencyHelper.getSymbolFromCurrency(CurrencyCode.SEK):
        return `${this.formatNumberForPrices(value)} ${CurrencyHelper.currencySymbol}`;
      default:
        return strValueWithSignToCurrency(this.formatNumberForPrices(value), CurrencyHelper.currencySymbol);
    }
  }

  private static formatTime(milliseconds: number, tooltip: boolean, allowSecondsFraction = true): string {
    if (!milliseconds || Math.trunc(milliseconds) === 0) {
      return '0 s';
    }

    if (milliseconds < 0) {
      return this.getOpposite(this.formatTime(-milliseconds, tooltip), '0 s');
    }

    if (milliseconds < 60000 && allowSecondsFraction) {
      return `${this.format(milliseconds / 1000, FormatType.Number, false, tooltip)} s`;
    }

    const seconds = Math.trunc(milliseconds / 1000);
    const parts = this.getTimeParts(seconds).filter(p => p.value > 0);
    const selectedParts = tooltip ? parts : parts.length > 1 && parts[0].index + 1 === parts[1].index ? parts.slice(0, 2) : parts.slice(0, 1);
    return selectedParts.map(p => `${p.value} ${p.unit}`).join(' ');
  }

  private static getTimeParts(seconds: number): { value: number; unit: string; index: number }[] {
    const definitions = [
      { unit: 's', coefficient: 60 },
      { unit: 'min', coefficient: 60 },
      { unit: 'h', coefficient: 24 },
      { unit: 'd', coefficient: 30 },
      { unit: 'm', coefficient: 1000 },
    ];

    let value = seconds;
    const parts = [];
    for (let i = 0; i < definitions.length; i++) {
      const definition = definitions[i];
      parts.push({ value: value % definition.coefficient, unit: definition.unit, index: definitions.length - 1 - i });
      value = Math.trunc(value / definition.coefficient);
    }
    return parts.reverse();
  }

  private static addSign(value: string): string {
    if (!value || (value.startsWith('0') && !value.includes('.') && !value.includes(',')) || value.startsWith('-')) {
      return value;
    } else {
      return `+ ${value}`;
    }
  }

  private static formatBytes(bytes: number, decimals: number): string {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public static getOrdinal(i: number): string {
    if (i < 0) {
      return this.getOrdinal(-i);
    }

    const mod10 = i % 10;
    const mod100 = i % 100;
    return mod10 === 1 && mod100 !== 11 ? 'st' : mod10 === 2 && mod100 !== 12 ? 'nd' : mod10 === 3 && mod100 !== 13 ? 'rd' : 'th';
  }
}

<ng-container [formGroup]="form">
  <div formArrayName="parameters" class="parameters-gap">
    @for (parameter of f.parameters.controls; track parameter; let i = $index) {
      <div>
        <div class="flex-row flex-gap-8" [formGroupName]="i">
          @if (f.parameters.controls[i]?.controls.name; as paramName) {
            <div>
              <input type="text" name="name" formControlName="name" class="form-control" placeholder="Name" autocomplete="off" (blur)="onBlur(paramName)" />
              @if ((paramName.touched || paramName.dirty) && paramName.invalid) {
                <mat-error>
                  <span>Please enter a name</span>
                </mat-error>
              }
            </div>
          }
          <span class="select-content-width">
            <mat-select class="pointee-mat-select" placeholder="Select a type" name="type" formControlName="type">
              @for (type of parameterTypes; track type) {
                <mat-option [value]="type">{{type}}</mat-option>
              }
            </mat-select>
          </span>
          @if (showValueInput) {
            <div>
              <input type="text" name="value" formControlName="value" class="form-control" placeholder="Value" autocomplete="off" />
            </div>
          }
          <span class="p-l-8">
            <button type="button" class="btn btn-icon-only" aria-label="Remove parameter" (click)="removeParameter(i)" [disabled]="isDisabled">
              <svg-icon key="delete-24" size="md"></svg-icon>
            </button>
          </span>
        </div>
      </div>
    }
  </div>
  <button type="button" class="btn btn-link m-t-8" (click)="addParameter()" [disabled]="isDisabled"><svg-icon key="plus"></svg-icon>Add a startup parameter</button>
</ng-container>

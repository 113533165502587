import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar-input.component.html',
  styleUrls: ['./search-bar-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarInputComponent implements AfterViewInit {
  @Input() searchValue = '';
  @Input() placeholder: string;
  @Input() hasFocus = true;
  @Input() toggleOn = false;
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.searchValue = '';
    this.applySearch();
    this.clear.next(this.searchValue);
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => this.applySearch());
    if (this.hasFocus) {
      this.setFocus();
    }
  }

  applySearch(): void {
    this.search.emit(this.searchValue);
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.searchValue = '';
    this.applySearch();
    this.clear.next(this.searchValue);
  }

  setFocus(): void {
    if (this.searchInput && this.hasFocus) {
      const inputElm = this.searchInput.nativeElement as HTMLInputElement;
      inputElm.focus();
      inputElm.select();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { FilterDefinition, FilterItem, FilterItemType } from '../../filtering/filter-definition.type';
import { FilterSelectionMap, FilteringHelper } from '../../filtering/filtering.helper';
import { FilteringService } from '../../filtering/filtering.service';
import { SelectItem, Selection } from '../select-with-search/select-item.type';

@Component({
  selector: 'shared-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  @Input() set items(value: FilterItem[]) {
    if (value == null) {
      return;
    }

    this.filteringService.initializeFilters(value);
  }

  @Output() filterChanged = new EventEmitter<FilterDefinition>();

  readonly FilterItemType = FilterItemType;
  filterDefinition$: Observable<FilterDefinition>;
  filterSelectionMap: FilterSelectionMap = new Map();

  constructor(private filteringService: FilteringService) {}

  async ngOnInit(): Promise<void> {
    this.filterDefinition$ = this.filteringService.filterDefinitionChanged$().pipe(
      tap(f => (this.filterSelectionMap = FilteringHelper.createFilterSelectionMap(f))),
      tap(f => this.filterChanged.emit(f))
    );
  }

  async onFilterValueChanged(selection: Selection, filter: FilterItem): Promise<void> {
    // Filtering for Single select only
    if (filter.type !== FilterItemType.Select) {
      return;
    }

    const selectedItem = selection as SelectItem;
    if (selectedItem.id === FilteringHelper.AllItemsId) {
      await this.filteringService.clearFilter(filter.name);
      return;
    }

    await this.filteringService.selectFilterValue(filter.name, selectedItem.id);
    return;
  }

  async onFilterClosed(selection: Selection, filter: FilterItem): Promise<void> {
    // Filtering for Multi select only
    if (filter.type !== FilterItemType.MultiSelect) {
      return;
    }

    if (selection === 'all') {
      await this.filteringService.clearFilter(filter.name);
      return;
    }

    const selectedItems = selection as SelectItem[];
    await this.filteringService.selectFilterValue(
      filter.name,
      selectedItems.map(i => i.id)
    );
  }
}

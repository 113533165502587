{
  "name": "pointee",
  "version": "1.60.5-7435",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=127.0.0.1",
    "build-all": "ng build --stats-json --configuration production",
    "build-all-dev": "ng build --stats-json --configuration development",
    "test": "ng test",
    "test-ci": "ng test --watch false --progress false --browsers ChromeHeadlessCI",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "generate-icons": "svgo -r --pretty -f ./src/assets/svg && svg-generator",
    "find-unused-icons": "node find-unused-icons.js"
  },
  "private": true,
  "svgGenerator": {
    "srcPath": "./src/assets/svg",
    "outputPath": "./src/app/svg",
    "svgoConfig": {
      "plugins": [
        "cleanupAttrs",
        "removeDimensions"
      ]
    }
  },
  "dependencies": {
    "@angular/animations": "^18.1.4",
    "@angular/cdk": "^18.1.4",
    "@angular/common": "^18.1.4",
    "@angular/compiler": "^18.1.4",
    "@angular/core": "^18.1.4",
    "@angular/forms": "^18.1.4",
    "@angular/localize": "^18.1.4",
    "@angular/material": "^18.1.4",
    "@angular/material-luxon-adapter": "18.1.4",
    "@angular/platform-browser": "^18.1.4",
    "@angular/platform-browser-dynamic": "^18.1.4",
    "@angular/router": "^18.1.4",
    "@auth0/angular-jwt": "^5.2.0",
    "@azure/msal-browser": "^3.20.0",
    "@cloudfactorydk/ng2-tooltip-directive": "^18.0.0",
    "@ctrl/ngx-codemirror": "^7.0.0",
    "@fontsource/roboto": "^5.0.14",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/luxon3": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@hpcc-js/wasm-graphviz": "^1.0.3",
    "@microsoft/applicationinsights-web": "^3.3.1",
    "@microsoft/signalr": "^8.0.7",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ngneat/svg-icon": "^4.1.0",
    "bootstrap": "^5.3.2",
    "chart.js": "^4.4.3",
    "chartjs-adapter-luxon": "^1.3.1",
    "chartjs-chart-matrix": "^2.0.1",
    "chartjs-plugin-annotation": "^3.0.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "chartjs-plugin-zoom": "^2.0.1",
    "codemirror": "^5.65.15",
    "flag-icons": "^7.2.3",
    "include-media": "^2.0.0",
    "karma-junit-reporter": "^2.0.1",
    "luxon": "^3.5.0",
    "ml-regression-simple-linear": "^3.0.1",
    "ng-multiselect-dropdown": "^1.0.0",
    "ng-table-virtual-scroll": "^1.6.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^18.0.0",
    "ngx-mat-select-search": "^7.0.6",
    "rxjs": "^7.8.1",
    "save-svg-as-png": "^1.4.17",
    "stacktrace-js": "^2.0.2",
    "svg-pan-zoom": "^3.6.1",
    "sweetalert2": "^11.12.4",
    "timelines-chart": "^2.12.1",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular/cli": "^18.1.4",
    "@angular/compiler-cli": "^18.1.4",
    "@angular/language-service": "^18.1.4",
    "@ngneat/falso": "^7.2.0",
    "@ngneat/svg-generator": "^4.0.0",
    "@types/jasmine": "^5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22.2.0",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "18.3.1",
    "eslint": "^9.9.1",
    "eslint-plugin-only-warn": "^1.1.0",
    "glob": "^11.0.0",
    "jasmine-core": "~5.2.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "prettier": "^3.3.3",
    "sass": "^1.77.8",
    "svgo": "^3.3.2",
    "ts-node": "~10.9.2",
    "typescript": "^5.5.4",
    "typescript-eslint": "8.2.0"
  }
}
